// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-archives-tsx": () => import("./../src/pages/archives.tsx" /* webpackChunkName: "component---src-pages-archives-tsx" */),
  "component---src-templates-archives-tsx": () => import("./../src/templates/archives.tsx" /* webpackChunkName: "component---src-templates-archives-tsx" */),
  "component---src-templates-post-list-tsx": () => import("./../src/templates/post-list.tsx" /* webpackChunkName: "component---src-templates-post-list-tsx" */),
  "component---src-templates-post-page-tsx": () => import("./../src/templates/post-page.tsx" /* webpackChunkName: "component---src-templates-post-page-tsx" */)
}

